import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import it_strategy_development_process from '../../images/pages/it_strategy_development_process.jpg'
import title_image from '../../images/titles/it_strategy.jpg'
import person from '../../images/person.png'

const pageTitle = "IT Strategy";
const pageSlug = "it-strategy";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We know how to develop IT capabilities into engines of business value.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Understanding the role of IT and strategy is crucial in understanding how to best leverage 
                    your technology capabilities.
                    </h3>
                    <p>Much like financing and marketing, IT enables business capabilities and is crucial to any 
                        functioning strategic effort.</p>
                    <p>At Nuerex, our professionals can work with leadership, and IT teams to identify the role of 
                        technology in business strategy. What benefits IT can provide and how an IT organization 
                        needs to be managed to deliver value.</p>
                    <p>We work with clients to understand the return on investment that can be obtained from current 
                        and leading technologies.</p>
                     <p>Is it time to update and improve your IT strategy?</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                    <span className="break"></span>
                    <h4>IT Service Management Services</h4>
                    <p>Our services ensure that IT consistently delivers results to your business, 
                        and also addresses common business concerns.</p>
                        <span className="break"></span>
                    <h4>IT Strategy Service Capabilities</h4>
                    <p>Our IT Strategy Services team helps clients develop their IT capabilities into engines of business value. 
                        We conduct this through a collaborative approach, decision-driven, and execution-focused projects.</p>
                    <span className="break"></span>
                    <h4>Business and IT Alignment</h4>
                    <p>We focus on understanding the role of IT and how it serves as a strategic asset and value creator. 
                        Our services for this area include:</p>
                    <ul>
                        <li>Corporate and IT Governance Alignment</li>
                        <li>IT Portfolio Lifecycle Management</li>
                        <li>IT Service Management</li>
                        <li>IT Strategy Assessments</li>
                        <li>Enterprise Architecture</li>
                    </ul>
                    <span className="break"></span>
                    <h4>IT Leadership and Management</h4>
                    <p>We directly advise our partners on how to manage their IT organization, including staff, vendors, 
                        funding, technology, and other critical areas. We Achieve this through services such as:</p>
                    <ul>
                        <li>CIO Mentoring</li>
                        <li>Software Selection Services</li>
                        <li>IT Organizational and Cost Assessments</li>
                        <li>Vendor Strategy Development</li>
                    </ul>
                    <span className="break"></span>
                    <h4>IT Transformation</h4>
                    <p>When changes need to be implemented to IT, we provide services and staff to advise on change 
                        planning and leadership management. Together we can conduct:</p>
                    <ul>
                        <li>Change Readiness Assessments</li>
                        <li>Business Continuity and Disaster Avoidance Design</li>
                        <li>Portfolio and Program Management Services</li>
                        <li>IT Service Management</li>
                        <li>Organization Redesign Projects</li>
                    </ul>
                    <span className="break"></span>
                    <h4>IT Strategy Development Process</h4>
                    <span className="break"></span>
                    <span className="break"></span>
                    <img src={it_strategy_development_process} />
                    <span className="break"></span>
                    <div className="contact-box">
                    <h3>Are you ready to develop your IT engines to create value continuously?</h3>  
                    <span className="break"></span>
                    <div className="button-area">
                        <Link to="/contact-us" className="button default">Contact us</Link>
                    </div>
                    </div>
                </div>
             {/*   <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
